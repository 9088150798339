import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { shopState } from './atoms'
import { usePosition } from 'use-position'
import _ from 'lodash'

import { SwipeableDrawer, Box, List, ListItem, ListSubheader, Chip, TextField, Divider } from '@material-ui/core'
import { styled } from '@material-ui/styles'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

import { gql, useQuery } from '@apollo/client'
const moment = require('moment')

const FilterHeader = styled(ListSubheader)({
    lineHeight: '2em',
    paddingTop: '10px',
})

export default function ShopSelector() {
    moment.locale('nb')

    const [shop, setShop] = useRecoilState(shopState)
    const { latitude, longitude, error } = usePosition()

    const [drawerIsOpen, setDrawerOpen] = useState(false)
    const [filterText, setFilterText] = useState('')

    const { loading, data } = useQuery(
        gql`
            {
                entity: allShops(filter: { stockLevel: { greaterThan: 0 } }) {
                    nodes {
                        id
                        lastUpdated
                        stockLevel
                        name
                        longitude
                        latitude
                    }
                }
            }
        `
    )

    const options = loading
        ? []
        : _.sortBy(data?.entity?.nodes, (shop) => {
              if (!latitude) return shop.name
              return (
                  Math.pow(shop.latitude - latitude, 2) + Math.pow((shop.longitude - longitude) * Math.cos(latitude), 2)
              )
          })

    const searchOptions = loading
        ? []
        : _.sortBy(
              data?.entity?.nodes.filter((o) => !filterText || o.name?.toLowerCase()?.match(filterText.toLowerCase())),
              'name'
          )

    // We only want to set the shop when either the latitude changes (which should only happen once)
    useEffect(() => {
        if (latitude && !_.isEmpty(options)) {
            setShop(options[0].id)
        }
        // This eslint disable does't smell tooo good, but I don't see anything that should make this a problem.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latitude, data])

    const selectedShop = data?.entity?.nodes?.filter((o) => o.id === shop)?.[0]
    const isSearching = !selectedShop && !error

    const lastUpdatedTimestamp = moment(selectedShop?.lastUpdated)

    return (
        <>
            <Box
                sx={{
                    paddingRight: '8px',
                    fontSize: '0.8rem',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    typography: 'condensed',
                    position: 'relative',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setDrawerOpen(!drawerIsOpen)
                }}
            >
                {!selectedShop && isSearching && 'Søker...'}
                {!selectedShop && error && 'Velg ditt pol'}
                {selectedShop && (
                    <Box sx={{ textAlign: 'right', paddingRight: '5px' }}>
                        <Box>{selectedShop?.name}</Box>
                        <Box sx={{ fontSize: '0.6rem', color: '#faa' }}>
                            Oppdatert{' '}
                            {lastUpdatedTimestamp.isSameOrAfter(moment().startOf('date'))
                                ? lastUpdatedTimestamp.format('[idag] HH:SS')
                                : lastUpdatedTimestamp.format('DD.MM.YYYY')}
                        </Box>
                    </Box>
                )}
                {!isSearching && (drawerIsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
            </Box>

            <SwipeableDrawer
                anchor="top"
                open={drawerIsOpen}
                onClose={() => {
                    setDrawerOpen(false)
                }}
                onOpen={() => {
                    setDrawerOpen(true)
                }}
                sx={{ zIndex: 1260 }}
            >
                <Box sx={{ paddingTop: '60px' }}>
                    <List>
                        {latitude && (
                            <>
                                <FilterHeader>I nærheten</FilterHeader>
                                <ListItem>
                                    <Box>
                                        {options.slice(0, 10).map((option) => (
                                            <Chip
                                                key={option.id}
                                                sx={{ marginRight: '10px', marginBottom: '8px' }}
                                                label={<Box component="span">{option.name}</Box>}
                                                size="small"
                                                onClick={() => {
                                                    setShop(option.id)
                                                    setDrawerOpen(false)
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </ListItem>
                                <Divider />
                                <FilterHeader>Alle</FilterHeader>
                            </>
                        )}
                        <ListItem>
                            <TextField
                                id="filled-search"
                                label="Søk..."
                                type="search"
                                fullWidth
                                value={filterText}
                                onChange={(event) => {
                                    setFilterText(event.target.value)
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <Box sx={{ height: '300px', overflow: 'auto' }}>
                                {searchOptions.map((option) => (
                                    <Chip
                                        key={option.name}
                                        sx={{ marginRight: '10px', marginBottom: '8px' }}
                                        label={<Box component="span">{option.name}</Box>}
                                        size="small"
                                        onClick={() => {
                                            setShop(option.id)
                                            setDrawerOpen(false)
                                        }}
                                    />
                                ))}
                            </Box>
                        </ListItem>
                    </List>
                </Box>
            </SwipeableDrawer>
        </>
    )
}
