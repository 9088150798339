import { atom, selector } from 'recoil'
import { gql } from '@apollo/client'

export const activeFiltersBoxHeightState = atom({
    key: 'activeFiltersBoxHeightState',
    default: 0,
})

export const filterState = atom({
    key: 'filterState',
    default: {},
})

export const shopState = atom({
    key: 'shopState',
    default: '',
})

export const wineQuery = selector({
    key: 'wineQuery',

    get: ({ get }) => {
        const shopId = get(shopState)
        // If a shop hasn't been selected, we return blank to avoid the initial GraphQL query
        if (!shopId) return null

        const filters = get(filterState)

        var filterClauses = Object.entries(filters).map(([filterName, filterValue]) => {
            if (filterName === 'matches')
                return `productMatchesByProductNo: { some: { match: { equalTo: "${filterValue}" } } }`
            if (filterName === 'tags')
                return `productTagsByProductNo: { some: { tagName: { equalTo: "${filterValue}" } } }`
            if (filterName === 'ingredients')
                return `productIngredientsByProductNo: { some: { ingredient: { equalTo: "${filterValue}" } } }`
            if (filterName === 'shoptags')
                return `productShopTagsByProductNo: { some: { tagName: { equalTo: "${filterValue}" }, shopId: { equalTo: "${shopId}"} } }`
            if (filterName === 'price') return `pricePerLitre: {lessThanOrEqualTo: "${filterValue / 0.75}"}`

            return `${filterName}: { equalTo: "${filterValue}"}`
        })

        const filterString = filterClauses ? `${filterClauses.join(', ')}` : ''

        return gql`
            {
                results: allProducts(
                    filter: { 
                        productShopsByProductNo: { some: { shopId: { equalTo: "${shopId}" } } } 
                        ${filterString}
                    }
                    orderBy: SORT_AVERAGE_RATING_AND_PRICE_ASC
                    first: 50
                ) {
                    totalCount
                    country: groupedAggregates(groupBy: COUNTRY) {
                        keys
                        distinctCount {
                            productNo
                        }
                    }
                    productType: groupedAggregates(groupBy: PRODUCT_TYPE) {
                        keys
                        distinctCount {
                            productNo
                        }
                    }
                    district: groupedAggregates(groupBy: DISTRICT) {
                        keys
                        distinctCount {
                            productNo
                        }
                    }
                    subDistrict: groupedAggregates(groupBy: SUB_DISTRICT) {
                        keys
                        distinctCount {
                            productNo
                        }
                    }
                    productSelection: groupedAggregates(groupBy: PRODUCT_SELECTION) {
                        keys
                        distinctCount {
                            productNo
                        }
                    }
                    volumeGroup: groupedAggregates(groupBy: VOLUME_GROUP) {
                        keys
                        distinctCount {
                            productNo
                        }
                    }
                    style: groupedAggregates(groupBy: STYLE) {
                        keys
                        distinctCount {
                            productNo
                        }
                    }
                    nodes {
                        productNo
                        productType
                        productGroup
                        name
                        producer
                        country
                        district
                        subDistrict
                        vintage
                        url
                        imageUrl
                        price
                        volume
                        volumeGroup
                        pricePerLitre
                        alcoholLevel
                        productSelection
                        style
                        averageRatingCurrentVintage
                        averageRatingAllVintages
                        productShopsByProductNo(condition: { shopId: "${shopId}" }) {
                            nodes {
                                shopId
                                stockLevel
                            }
                        }
                        productReviewsByProductNo {
                            nodes {
                                notes
                                rating
                                reviewDate
                                reviewSource
                                reviewer
                                url
                                vintage
                            }
                        }
                        productMatchesByProductNo {
                            nodes {
                                match
                            }
                        }
                        productIngredientsByProductNo {
                            nodes {
                                ingredient
                                percentage
                            }
                        }
                        productTagsByProductNo {
                            nodes {
                                tagName
                                tagCategory
                            }
                        }
                    }
                }
                allProductMatches( filter: { productByProductNo: { 
                            productShopsByProductNo: { some: { shopId: { equalTo: "${shopId}" } } } 
                            ${filterString}
                        }}) { groupedAggregates(groupBy: MATCH) { keys, distinctCount { productNo } } 
                }
                allProductIngredients( filter: { productByProductNo: { 
                    productShopsByProductNo: { some: { shopId: { equalTo: "${shopId}" } } } 
                    ${filterString}
                    }}) { groupedAggregates(groupBy: INGREDIENT) { keys, distinctCount { productNo } } 
                }
                allProductTags( filter: { productByProductNo: { 
                    productShopsByProductNo: { some: { shopId: { equalTo: "${shopId}" } } } 
                    ${filterString}
                    }}) { groupedAggregates(groupBy: TAG_NAME) { keys, distinctCount { productNo } } 
                }
                allProductShopTags(
                    filter: {
                        shopId: {equalTo: "${shopId}"}
                        productByProductNo: {
                            productShopsByProductNo: {some: {shopId: {equalTo: "${shopId}"}}}
                            ${filterString}
                    }}) {
                    groupedAggregates(groupBy: TAG_NAME) {
                      keys
                      distinctCount {
                        productNo
                      }
                    }
                  }
                
            }
        `
    },
})
