import React, { useState } from 'react'
import axios from 'axios'

import {
    Fab,
    Dialog,
    DialogContent,
    Chip,
    Typography,
    Box,
    TextField,
    Button,
    FormHelperText,
    Snackbar,
    IconButton,
} from '@material-ui/core'
import { useFormik } from 'formik'

import AutoAwesomeIcon from '@material-ui/icons/AutoAwesome'
import ErrorIcon from '@material-ui/icons/Error'
import SendIcon from '@material-ui/icons/Send'
import ChatIcon from '@material-ui/icons/Chat'
import CloseIcon from '@material-ui/icons/Close'

const SUGGESTION = 'suggestion'
const BUG = 'bug'

export default function FeedbackForm(props) {
    const [dialogIsShown, showDialog] = useState(false)
    const [confirmationIsShown, showConfirmation] = useState(false)

    const formik = useFormik({
        initialValues: { feedback: '', email: '', name: '', feedbackType: SUGGESTION },
        validate: (values) => {
            const errors = {}
            if (!values.feedbackType) errors.feedbackType = 'Feil'
            if (!values.feedback) errors.feedback = 'Feil'
            if (!values.name) errors.name = 'Feil'
            return errors
        },
        onSubmit: (values) => {
            console.log('Submitting feedback')
            axios.post('/sendFeedback', values).then((response) => {
                console.log(response.data)
                formik.setSubmitting(false)
                showDialog(false)
                showConfirmation(true)
            })
        },
    })

    return (
        <>
            <Fab
                color="primary"
                sx={{ position: 'fixed', right: '30px', bottom: '30px', zIndex: 2000 }}
                onClick={() => showDialog(!dialogIsShown)}
            >
                <ChatIcon sx={{ fontSize: '2rem' }} />
            </Fab>

            <Dialog
                onClose={() => showDialog(false)}
                open={dialogIsShown}
                sx={{
                    '& .MuiDialog-scrollPaper': { alignItems: 'flex-end', justifyContent: 'flex-end' },

                    '& .MuiPaper-root': {
                        marginBottom: '100px',
                        marginRight: '30px',
                        maxWidth: '440px',
                    },
                }}
                scroll="paper"
            >
                <DialogContent>
                    <Typography>
                        Vi syns det er veldig hyggelig at du bruker Vinolino! <br />
                        Dette er et hobbyprosjekt, så vi kan ikke love å levere på alle ønsker - men all ris, ros, og
                        forslag til forbedringer blir satt stor pris på. Hilsen Vinolino-teamet!
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ textAlign: 'center', marginTop: '15px' }}>
                            <Chip
                                icon={<AutoAwesomeIcon />}
                                label="Forslag"
                                variant={formik.values.feedbackType === SUGGESTION ? 'default' : 'outlined'}
                                onClick={() => formik.setFieldValue('feedbackType', SUGGESTION)}
                                color={formik.values.feedbackType === SUGGESTION ? 'success' : 'default'}
                                sx={{ height: '40px', marginRight: '10px', width: '45%' }}
                            />
                            <Chip
                                icon={<ErrorIcon />}
                                label="Feilmelding"
                                variant={formik.values.feedbackType === BUG ? 'default' : 'outlined'}
                                onClick={() => formik.setFieldValue('feedbackType', BUG)}
                                color={formik.values.feedbackType === BUG ? 'error' : 'default'}
                                sx={{ height: '40px', width: '45%' }}
                            />
                            <FormHelperText id="my-helper-text" error={true}>
                                {formik.errors.feedbackType}
                            </FormHelperText>
                        </Box>

                        <TextField
                            label={
                                formik.values.feedbackType === BUG ? 'Beskrivelse av problemet' : 'Din tilbakemelding'
                            }
                            placeholder={
                                formik.values.feedbackType === BUG
                                    ? 'For feilrapporter er det supert med så mange detaljer som mulig: Butikk, filterkombinasjon, osv. Da blir det lettere å identifisere rotårsaken!'
                                    : ''
                            }
                            id="feedback"
                            value={formik.values.feedback}
                            onChange={formik.handleChange}
                            error={formik.touched.feedback && Boolean(formik.errors.feedback)}
                            multiline
                            rows={6}
                            color="text"
                            sx={{ width: '100%', marginTop: '15px' }}
                        />

                        <TextField
                            label="Hva heter du?"
                            sx={{ width: '100%', marginTop: '15px' }}
                            id="name"
                            color="text"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                        />

                        <TextField
                            label="Hva er epostadressen din?"
                            sx={{ width: '100%', marginTop: '15px' }}
                            id="email"
                            color="text"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                        />

                        <Box sx={{ textAlign: 'right' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ marginTop: '15px' }}
                                fullWidth={true}
                                endIcon={<SendIcon />}
                            >
                                Send inn tilbakemelding
                            </Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={confirmationIsShown}
                onClose={() => showConfirmation(false)}
                message="Takk for tilbakemeldingen!"
                action={
                    <IconButton size="small" color="inherit" onClick={() => showConfirmation(false)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                sx={{ '& .MuiPaper-root': { backgroundColor: '#393' }, marginBottom: '15px' }}
            />
        </>
    )
}
