import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useQuery } from '@apollo/client'
import _, { toInteger } from 'lodash'

import { filterState, wineQuery } from './atoms'
import { vinolinoIcons } from './theme'

import {
    Avatar,
    Box,
    Chip,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/core'

import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { styled } from '@material-ui/styles'

export default function Sidebar(props) {
    const wineQueryText = useRecoilValue(wineQuery)
    const { loading, error, data } = useQuery(wineQueryText, {
        skip: wineQueryText === null,
    })

    if (loading)
        return (
            <Box sx={{ textAlign: 'center', paddingTop: '120px' }}>
                <CircularProgress />
            </Box>
        )

    if (loading || error) return <></>

    return (
        <List
            sx={{
                paddingTop: 0,
            }}
        >
            <MaxPriceFilter name="maxPrice" label="Makspris" />

            <ChipFilter name="country" label="Land" optionsData={data?.results?.country} />
            <ListFilter name="productType" label="Produkttype" optionsData={data?.results?.productType} />

            <ChipFilter name="district" label="Distrikt" optionsData={data?.results?.district} />

            <ChipFilter name="ingredients" label="Drue" optionsData={data?.allProductIngredients?.groupedAggregates} />

            <ChipFilter name="matches" label="Passer til" optionsData={data?.allProductMatches?.groupedAggregates} />

            <ChipFilter name="style" label="Stil" optionsData={data?.results?.style} />

            <ChipFilter name="productSelection" label="Produktutvalg" optionsData={data?.results?.productSelection} />

            <ChipFilter name="tags" label="Tags" type="chip" optionsData={data?.allProductTags?.groupedAggregates} />

            <ChipFilter name="shoptags" label="Annen moro" optionsData={data?.allProductShopTags?.groupedAggregates} />

            <ChipFilter name="volumeGroup" label="Volum" optionsData={data?.results?.volumeGroup} />
        </List>
    )
}

const FilterHeader = styled(ListSubheader)({
    lineHeight: '2em',
    paddingTop: '10px',
})

const MaxPriceFilter = function (props) {
    const [filters, setFilters] = useRecoilState(filterState)

    const price = filters['price']

    // If filter is already chosen, it should not be shown as a choice
    if (price) return <></>

    const setPrice = (filterValue) => {
        setFilters(_.set(_.clone(filters), 'price', filterValue))
    }

    return (
        <>
            <FilterHeader>{props.label}</FilterHeader>
            <ListItem>
                <ToggleButtonGroup
                    value={`${price}`}
                    exclusive
                    color="primary"
                    onChange={(event, newFilterValue) => {
                        if (newFilterValue !== null) setPrice(newFilterValue)
                    }}
                    aria-label="price filter"
                >
                    {['1000', '750', '500', '400', '300', '200'].map((p) => (
                        <ToggleButton value={p} aria-label={p} size="small">
                            {p}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </ListItem>
        </>
    )
}

const ChipFilter = function (props) {
    const [filters, setFilters] = useRecoilState(filterState)

    const [expanded, setExpanded] = useState(false)

    const filterName = props.name
    const filterLabel = props.label

    const updateFilterValue = (filterValue) => {
        setFilters(_.set(_.clone(filters), filterName, filterValue))
    }

    // If filter is already chosen, it should not be shown as a choice
    if (filters[filterName]) return <></>

    const results = _.sortBy(props.optionsData, (o) => -toInteger(o.distinctCount.productNo))
        ?.map((option) => {
            return {
                value: option.keys[0],
                count: option.distinctCount.productNo,
            }
        })
        .filter((option) => option.value !== '(Missing)')

    if (results.length === 0) return <></>

    return (
        <>
            <Divider />
            <FilterHeader>{filterLabel}</FilterHeader>
            <ListItem>
                <Box>
                    {results.map(
                        (option, i) =>
                            (i < 8 || expanded) && (
                                <Chip
                                    key={option.value}
                                    sx={{ marginRight: '4px', marginBottom: '8px' }}
                                    label={
                                        <Box component="span">
                                            {option.value}
                                            <Box
                                                component="span"
                                                sx={{
                                                    backgroundColor: 'primary.main',
                                                    color: 'white',
                                                    marginLeft: '4px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px',
                                                    paddingTop: '1px',
                                                    paddingBottom: '1px',
                                                    borderRadius: '6px',
                                                    fontSize: '0.8em',
                                                }}
                                            >
                                                {option.count}
                                            </Box>
                                        </Box>
                                    }
                                    size="small"
                                    onClick={() => {
                                        updateFilterValue(option.value)
                                    }}
                                />
                            )
                    )}
                </Box>
            </ListItem>
            {results.length > 10 && (
                <ListItem
                    sx={{
                        paddingTop: 0,
                        justifyContent: 'center',
                    }}
                    onClick={() => setExpanded(!expanded)}
                    button
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            )}
        </>
    )
}

const ListFilter = function (props) {
    const [filters, setFilters] = useRecoilState(filterState)

    const filterName = props.name
    const filterLabel = props.label

    const updateFilterValue = (filterValue) => {
        setFilters(_.set(_.clone(filters), filterName, filterValue))
    }

    // If filter is already chosen, it should not be shown as a choice
    if (filters[filterName]) return <></>

    const results = _.sortBy(props.optionsData, (o) => -toInteger(o.distinctCount.productNo))
        ?.map((option) => {
            return {
                value: option.keys[0],
                count: option.distinctCount.productNo,
            }
        })
        .filter((option) => option.value !== '(Missing)')

    if (results.length === 0) return <></>

    return (
        <Box>
            <FilterHeader>{filterLabel}</FilterHeader>
            {results.map((option) => (
                <ListItem
                    button
                    key={option.value}
                    onClick={() => {
                        updateFilterValue(option.value)
                    }}
                >
                    <ListItemIcon>
                        <Avatar variant="square" src={vinolinoIcons[option.value]} alt="" />
                    </ListItemIcon>
                    <ListItemText primary={option.value} />
                    <Box
                        component="span"
                        sx={{
                            backgroundColor: '#944',
                            color: '#fff',
                            marginLeft: '8px',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingTop: 0,
                            paddingBottom: 0,
                            borderRadius: '8px',
                            fontSize: '0.8em',
                        }}
                    >
                        {option.count}
                    </Box>
                </ListItem>
            ))}
        </Box>
    )
}
