import React from 'react'
import { useQuery } from '@apollo/client'
import { useRecoilValue } from 'recoil'
import { wineQuery } from './atoms'

import {
    CardContent,
    CardActions,
    ListItemText,
    Card,
    List,
    ListItem,
    ListItemIcon,
    Collapse,
    Grid,
    Dialog,
    IconButton,
    Box,
    Avatar,
    Typography,
    Chip,
    CircularProgress,
    Link,
    Button,
} from '@material-ui/core'
import { vinolinoIcons } from './theme'

import { Close } from '@material-ui/icons'
import _, { toInteger } from 'lodash'

export default function WinesList() {
    const [openWine, setOpenWine] = React.useState()
    const [fullScreenWineDisplayed, setFullScreenWineDisplayed] = React.useState()

    const wineQueryText = useRecoilValue(wineQuery)
    const { loading, error, data } = useQuery(wineQueryText, {
        skip: wineQueryText === null,
    })

    const handleWineClick = (wineNo) => {
        if (openWine === wineNo) setOpenWine()
        else setOpenWine(wineNo)
    }

    if (loading)
        return (
            <Box sx={{ textAlign: 'center', paddingTop: '120px' }}>
                <CircularProgress />
            </Box>
        )
    if (error) return <p>Error :(</p>

    return (
        <Box>
            <List>
                {data?.results?.nodes?.map((product) => (
                    <Box key={product.productNo}>
                        <ListItem button dense onClick={() => handleWineClick(product.productNo)}>
                            <ListItemIcon>
                                <ListItemIcon>
                                    <Avatar variant="square" src={vinolinoIcons[product.productGroup]} alt="" />
                                    {(product.averageRatingCurrentVintage || product.averageRatingAllVintages) && (
                                        <Box
                                            component="span"
                                            sx={{
                                                position: 'absolute',
                                                display: 'block',
                                                top: '32px',
                                                backgroundColor: product.averageRatingCurrentVintage ? '#333' : '#ccc',
                                                color: 'white',
                                                borderRadius: '5px',
                                                left: '10px',
                                                paddingLeft: '2px',
                                                paddingRight: '2px',
                                                fontSize: '0.8em',
                                                lineHeight: '1.3em',
                                            }}
                                        >
                                            {toInteger(
                                                product.averageRatingCurrentVintage || product.averageRatingAllVintages
                                            )}
                                        </Box>
                                    )}
                                </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText
                                sx={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: openWine === product.productNo ? 'wrap' : 'nowrap',
                                    overflow: 'hidden',
                                }}
                                primary={<Typography variant="condensed">{product.name}</Typography>}
                                secondary={
                                    <Typography sx={{ typography: 'condensed' }}>
                                        <>{product.country} </>
                                        {product.district && <>› {product.district} </>}
                                        {product.subDistrict &&
                                            product.subDistrict !== 'Øvrige' &&
                                            product.subDistrict !== '(Missing)' && <>› {product.subDistrict} </>}
                                    </Typography>
                                }
                            />
                            <Box sx={{ fontSize: '0.9em', paddingLeft: '5px', typography: 'condensed' }}>
                                {product.price}
                                {product.volume !== '0.75' && '*'}
                            </Box>
                        </ListItem>
                        <Collapse in={openWine === product.productNo}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <Box
                                                component="img"
                                                sx={{ maxWidth: '34px' }}
                                                src={product.imageUrl}
                                                alt="Bottle"
                                                onClick={() => setFullScreenWineDisplayed(true)}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            {product.producer && (
                                                <Box
                                                    sx={{
                                                        fontSize: '0.8rem',
                                                        paddingBottom: '8px',
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    {product.producer}
                                                </Box>
                                            )}

                                            <Box sx={{ fontSize: '0.8rem', paddingBottom: '8px' }}>
                                                {product?.productMatchesByProductNo?.nodes.length > 0 && (
                                                    <>
                                                        Passer til{' '}
                                                        {product.productMatchesByProductNo.nodes
                                                            .map((match) => match.match.toLowerCase())
                                                            .join(', ')}
                                                        .{' '}
                                                    </>
                                                )}
                                                {product?.productIngredientsByProductNo?.nodes.length > 0 && (
                                                    <>
                                                        {product.productIngredientsByProductNo.nodes.map(
                                                            (ingredient, index) => (
                                                                <>
                                                                    {index > 0 && ', '}
                                                                    {ingredient.percentage}% {ingredient.ingredient}
                                                                </>
                                                            )
                                                        )}
                                                        .{' '}
                                                    </>
                                                )}
                                                {product.alcoholLevel && (
                                                    <>{parseFloat(product.alcoholLevel)}% alkohol. </>
                                                )}
                                                {product.productSelection && <>{product.productSelection}. </>}
                                                {product.volume !== '0.75' && <>{parseFloat(product.volume)} liter. </>}
                                            </Box>

                                            <Box sx={{ fontSize: '0.8rem', paddingBottom: '8px' }}>
                                                {_.sortBy(product?.productReviewsByProductNo?.nodes, (o) => {
                                                    return Math.abs(o.vintage - product.vintage)
                                                }).map(
                                                    (review, i) =>
                                                        i === 0 && (
                                                            <Box component="span" key={i} sx={{ paddingRight: '5px' }}>
                                                                {product.vintage !== review.vintage && (
                                                                    <Box
                                                                        component="span"
                                                                        sx={{
                                                                            marginRight: '5px',
                                                                            fontStyle: 'italic',
                                                                        }}
                                                                    >
                                                                        (For {review.vintage}-årgangen)
                                                                    </Box>
                                                                )}
                                                                <strong>{review.rating} poeng.</strong> {review.notes}{' '}
                                                                <Link
                                                                    sx={{ textDecoration: 'none' }}
                                                                    href={review.url}
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                >
                                                                    ({review.reviewSource})
                                                                </Link>
                                                            </Box>
                                                        )
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        href={product.url}
                                        rel="noreferrer"
                                        size="small"
                                        variant="contained"
                                        target="_blank"
                                    >
                                        Vinmonopolet
                                    </Button>
                                </CardActions>
                            </Card>
                            <Dialog
                                fullScreen
                                open={openWine === product.productNo && fullScreenWineDisplayed ? true : false}
                                onClose={() => setFullScreenWineDisplayed(false)}
                            >
                                <IconButton
                                    edge="start"
                                    onClick={() => setFullScreenWineDisplayed(false)}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>{' '}
                                <Box
                                    component="img"
                                    src={product.imageUrl.replace('96x96', '1200x1200')}
                                    alt="Bottle"
                                    onClick={() => setFullScreenWineDisplayed(false)}
                                    sx={{
                                        height: 'calc(100vh - 60px)',
                                        objectFit: 'contain',
                                        objectPosition: 'center',
                                    }}
                                />
                            </Dialog>
                        </Collapse>
                    </Box>
                ))}
            </List>
        </Box>
    )
}
