import { createTheme } from '@material-ui/core/styles'

import RedWineIcon from './wine_icons/red_wine-40x40.png'
import RoseWineIcon from './wine_icons/rose_wine-40x40.png'
import DessertWineIcon from './wine_icons/dessert_wine-40x40.png'
import SparklingWineIcon from './wine_icons/sparkling_wine-40x40.png'
import BeerIcon from './wine_icons/beer-40x40.png'
import WhiteWineIcon from './wine_icons/white_wine-40x40.png'

export const vinolinoIcons = {
    Rødvin: RedWineIcon,
    Hvitvin: WhiteWineIcon,
    Rosévin: RoseWineIcon,
    Dessertvin: DessertWineIcon,
    'Musserende vin': SparklingWineIcon,
    Øl: BeerIcon,
}

export const theme = createTheme({
    palette: {
        primary: {
            main: '#933',
            light: '#fcc',
        },
    },
    typography: {
        fontSize: 12,
        condensed: {
            fontFamily: 'Barlow Semi Condensed',
        },
    },
})
