import React, { useRef, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import _ from 'lodash'

import { filterState, activeFiltersBoxHeightState } from './atoms'

import { Box, Chip } from '@material-ui/core'

export default function ActiveFilters(props) {
    const [filters, setFilters] = useRecoilState(filterState)

    const [, setBoxHeight] = useRecoilState(activeFiltersBoxHeightState)

    const activeFiltersBoxRef = useRef(null)

    const removeActiveFilter = (filterName) => {
        setFilters(_.omit(filters, filterName))
    }

    const labelValue = (filterName, filterValue) => {
        if (filterName === 'price') return `Maks ${filterValue} kr`
        return filterValue
    }

    useEffect(() => {
        setBoxHeight(activeFiltersBoxRef?.current?.clientHeight)
    })

    return _.isEmpty(filters) ? (
        <React.Fragment />
    ) : (
        <Box ref={activeFiltersBoxRef} sx={{ padding: '8px 8px 0px 8px' }}>
            {Object.entries(filters).map(([filterName, filterValue]) => (
                <Chip
                    key={filterValue}
                    sx={{
                        marginRight: '10px',
                        marginBottom: '8px',
                    }}
                    label={labelValue(filterName, filterValue)}
                    size="small"
                    onClick={() => {
                        removeActiveFilter(filterName)
                    }}
                    onDelete={() => {
                        removeActiveFilter(filterName)
                    }}
                />
            ))}
        </Box>
    )
}
