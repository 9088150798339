import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@material-ui/core/styles'
import WebFont from 'webfontloader'

import './index.css'
import App from './App'

import { theme } from './theme'

WebFont.load({
    google: {
        families: ['Lobster:400', 'Arapey:400'],
    },
})

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
    fetchOptions: {
        mode: 'no-cors',
    },
})

ReactDOM.render(
    <RecoilRoot>
        <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </ThemeProvider>
    </RecoilRoot>,
    document.getElementById('root')
)
