import React from 'react'
import { useRecoilValue } from 'recoil'
import { Drawer, Typography, AppBar, Toolbar, IconButton, Box } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import WinesList from './WinesList'
import Sidebar from './Sidebar'
import ShopSelector from './ShopSelector'
import FeedbackForm from './FeedbackForm'
import ActiveFilters from './ActiveFilters'
import { shopState, activeFiltersBoxHeightState } from './atoms'

export default function TheApp() {
    const [drawerIsOpen, setDrawerOpen] = React.useState(false)
    const shop = useRecoilValue(shopState)

    const activeFiltersBoxHeight = useRecoilValue(activeFiltersBoxHeightState) || 0

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="sticky" sx={{ zIndex: 1300 }}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: 'Lobster', fontSize: '2em' }}>
                        Vinolino
                    </Typography>
                    <ShopSelector />

                    {shop && (
                        <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(!drawerIsOpen)}>
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>

            <Box
                sx={{
                    position: 'fixed',
                    backgroundColor: '#eee',
                    zIndex: 1250,
                    width: '100%',
                }}
                boxShadow={3}
            >
                <ActiveFilters />
            </Box>

            {shop && (
                <>
                    <Box sx={{ marginTop: activeFiltersBoxHeight + 'px' }}>
                        <WinesList />
                    </Box>
                    <Drawer
                        anchor="right"
                        open={drawerIsOpen}
                        onClose={() => setDrawerOpen(false)}
                        sx={{ zIndex: 1200 }}
                    >
                        <Box
                            sx={{
                                marginTop: `${60 + activeFiltersBoxHeight}px`,
                                width: '250px',
                                fontSize: '1em',
                                position: 'relative',
                                overflow: 'auto',
                            }}
                        >
                            <Sidebar />
                        </Box>
                    </Drawer>
                </>
            )}
            <FeedbackForm />
        </Box>
    )
}
